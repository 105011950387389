import React from "react";

function PageContact()
{
    return (
        <div style={{
            height: "100vh",
            backgroundColor: "white",
            boxSizing: "border-box",
            paddingTop: "8vh",
            paddingLeft: "10px",
            paddingRight: "10px",
            textAlign: "center"
        }}>
            <h1>Contact me</h1>
            {/*<p>You can contact me through Discord or by sending an email to <a href="mailto:hi@snowp.io">hi@snowp.io</a></p>*/}
            <p>You can contact me through either option below.</p>
            <div style={{
                display: "flex",
                width: "100%",
                marginTop: "30px",
                boxSizing: "border-box",
                minHeight: "400px",
            }} className={"mobile-columns"}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <div>
                        {/*
                        <img src={process.env.PUBLIC_URL + "/discord.png"} alt="Snowp on Discord" style={{
                            borderRadius: 9999,
                            maxWidth: "200px",
                        }} />
                        <br/>*/}
                        <h1 style={{
                            marginBottom: 0
                        }}>Snowp#4826</h1>
                        <p style={{
                            marginTop: "10px"
                        }}>on Discord</p>
                    </div>
                </div>
                {/*
                <form style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexFlow: "column",
                    padding: "30px 10px",
                }} action="/contact/me" method="POST" className={"mobile-columns-split"}>
                    <input type="text" placeholder="Your name" name="name" autoComplete="off"/><br/>
                    <input type="email" placeholder="Your email address" name="email"/><br/>
                    <textarea placeholder="Your message" name="message"/><br/>
                    <button type="submit" className="btn" disabled={true}>Submit (still setting it up)</button>
                </form>*/}
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }} className={"mobile-columns-split"}>
                    <div>
                        <h1 style={{
                            marginBottom: 0
                        }}>hi@snowp.io</h1>
                        <p style={{
                            marginTop: "10px"
                        }}>by e-mail</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageContact;
