import React from "react";
import {Link} from "react-router-dom";

function PagePortfolioLRedis()
{   
    return (
        <div style={{
            height: "100vh",
            backgroundColor: "white",
            boxSizing: "border-box",
            paddingRight: "5vw",
            paddingLeft: "5vw",
            paddingTop: "8vh",
            textAlign: "center"
        }}>
            <h1>LRedis</h1>
            <p>LRedis is a proprietary server status toolset developed in collaboration with <a href="https://github.com/lhridder">lhridder</a> for <a href="https://ferox.host">Ferox Hosting</a></p>
            <p>It consists of multiple projects, of which <a href="https://status.ferox.host">LRedisWeb</a> and a Discord bot serve as the end-user information source, presenting Ferox Hosting status information.</p>
            <p>LRedis stretches the entire scope of the Ferox Hosting infrastructure, which consists of over 50 nodes. The toolset, as the name suggests, stores infrastructure state in a central Redis store.</p>
            <p>Responsible for Ferox Hosting's 99.9% uptime guarantee, it's one of the largest projects I've ever worked on.</p>
            <br/>
            <i>Technicalities:</i>
            <p>LRedis receives heartbeats from client nodes into a Redis store, then compiles it into summaries for internal usage and live status for clients.<br/>Most of the suite has been written in Java and Node.js, but is currently in a rapid transition towards Go.</p>
            <br/><br/>
            <div style={{
                display: "flex",
                width: "100%",
                boxSizing: "border-box",
            }} className={"mobile-columns"}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 10px"
                }}>
                    <img src="/static/portfolio-images/lredis-status.png" style={{
                        width: "100%",
                        height: "auto"
                    }}/>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 10px"
                }}>
                    <img src="/static/portfolio-images/lredis-monitor.png" style={{
                        width: "100%",
                        height: "auto"
                    }}/>
                </div>
            </div>
        </div>
    )
}

export default PagePortfolioLRedis;
