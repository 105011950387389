import React, {useEffect, useRef, useState} from 'react';
import {
    TransitionGroup,
    CSSTransition
} from "react-transition-group";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from 'react-router-dom';
import sal from "sal.js";
import "sal.js/dist/sal.css";

import "./App.css";

import PageHome from "./pages/Home";
import PageNotFound from "./pages/NotFound";
import PageAbout from "./pages/About";
import ErrorBoundary from "./ErrorBoundary";
import PageContact from "./pages/Contact";
import PagePortfolio from './pages/Portfolio';
import PagePortfolioLRedis from './pages/portfolio/LRedis';
import PagePortfolioNBashes from './pages/portfolio/NBashes';
import PagePortfolioSnowpio from './pages/portfolio/Snowp';
import PagePortfolioChatsock from './pages/portfolio/Chatsock';

function App() {
    const location = useLocation();
    const [scroll, setScroll] = useState(0);
    const [salInst, setSalInst] = useState();
    const [navMayTransparent, setNavMayTransparent] = useState(true);
    const [initial, setInitial] = useState(true);
    const pageTop = useRef(null);


    const scrollToTop = () => {
        // @ts-ignore
        if(pageTop.current != null) pageTop.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    useEffect(() => {
        const salInst = sal({
            threshold: 0.8,
            once: false
        });
        // @ts-ignore
        setSalInst(salInst);
    }, []);

    useEffect(() => {
        // @ts-ignore
        if(salInst) salInst.reset({
            threshold: 0.8,
            once: false
        });
    }, [salInst, location.key]);

    useEffect(() => {
        scrollToTop();
    }, [location.key]);


    useEffect(() => {
        const onScroll = (event: Event) => {
            if(!event.target) return;

            const documentElement: HTMLElement = (event.target as Document).documentElement;
            setScroll(Math.round(documentElement.scrollTop / documentElement.clientHeight * 100) / 100);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    useEffect(() => {
        if(initial)
        {
            setInitial(false);
            return;
        }

        setNavMayTransparent(false);
        const timeout = setTimeout(() => {
            setNavMayTransparent(true);
        }, 150);

        return () => clearTimeout(timeout);
    },[location.pathname]);


    return (
        <div ref={pageTop}>
            <div className={"nav"} style={{
                backgroundColor: (scroll <= 0.1 && location.pathname === "/" && navMayTransparent ? "transparent" : "black"),
                transition: (location.pathname === "/" ? "background-color 300ms linear" : "")
            }}>
                <Link to="/">Home</Link>
                <Link to="/portfolio">Portfolio</Link>
                <Link to="/about">About</Link>
            </div>
            <div className={"content"}>
                <ErrorBoundary>
                    <TransitionGroup>
                        <CSSTransition
                            timeout={500}
                            classNames='fade'
                            key={location.pathname}
                        >
                            <Switch location={location}>
                                <Route exact path="/portfolio/lredis">
                                    <div>
                                        <PagePortfolioLRedis/>
                                    </div>
                                </Route>
                                <Route exact path="/portfolio/nbashes">
                                    <div>
                                        <PagePortfolioNBashes/>
                                    </div>
                                </Route>
                                <Route exact path="/portfolio/snowpio">
                                    <div>
                                        <PagePortfolioSnowpio/>
                                    </div>
                                </Route>
                                <Route exact path="/portfolio/chatsock">
                                    <div>
                                        <PagePortfolioChatsock/>
                                    </div>
                                </Route>
                                <Route exact path="/portfolio">
                                    <div>
                                        <PagePortfolio/>
                                    </div>
                                </Route>

                                <Route exact path="/about">
                                    <div>
                                        <PageAbout/>
                                    </div>
                                </Route>
                                <Route exact path="/contact">
                                    <div>
                                        <PageContact/>
                                    </div>
                                </Route>
                                <Route exact path="/">
                                    <div>
                                        <PageHome/>
                                    </div>
                                </Route>
                                <Route path='*'>
                                    <div>
                                        <PageNotFound/>
                                    </div>
                                </Route>
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </ErrorBoundary>
            </div>
        </div>
    );
}

export default App;
