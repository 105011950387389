import React from "react";
import {Link} from "react-router-dom";

function PagePortfolioNBashes()
{   
    return (
        <div style={{
            height: "100vh",
            backgroundColor: "white",
            boxSizing: "border-box",
            paddingRight: "5vw",
            paddingLeft: "5vw",
            paddingTop: "8vh",
            textAlign: "center"
        }}>
            <h1>NBashes</h1>
            <p><a href="https://github.com/SnowpMakes/nbashes"><i>https://github.com/SnowpMakes/nbashes</i></a></p>
            <p>nbashes is a command line tool similar to <code>dialog</code> for use in bash scripts, specifically installation scripts.</p>
            <p>It presents the user with visual options during the installation process, which can be defined using command line parameters to nbashes within the script.</p>
            <p>nbashes is currently in use within several scripts internal to <a href="https://ferox.host">Ferox Hosting</a>.</p>
            <br/>
            <i>Technicalities:</i>
            <p>nbashes is written in C using ncurses (hence the name). It has been designed to be of minimal overhead and only be an interface to the user.</p>
            <br/><br/>
            {/*
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 10px",
            }}>
                <img src="/static/portfolio-images/nbashes-menu.png" style={{
                    width: "50%",
                    height: "auto"
                }}/>
            </div>
            */}
            <div style={{
                display: "flex",
                width: "100%",
                boxSizing: "border-box",
            }} className={"mobile-columns"}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 10px"
                }}>
                    <img src="/static/portfolio-images/nbashes-menu.png" style={{
                        width: "100%",
                        height: "auto"
                    }}/>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 10px"
                }}>
                    <img src="/static/portfolio-images/nbashes-usage.png" style={{
                        width: "100%",
                        height: "auto"
                    }}/>
                </div>
            </div>
        </div>
    )
}

export default PagePortfolioNBashes;
