import React, {useEffect, useRef, useState} from "react";
import {Parallax, Background} from "react-parallax";
import {SwitchTransition, CSSTransition} from "react-transition-group";
import {Link, useHistory} from "react-router-dom";

function PageHome()
{
    const content = useRef(null);
    const history = useHistory();
    const scrollToContent = () => {
        // @ts-ignore
        if(content.current != null) content.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const experience = [
        ["dashboards", "PHP using Laravel", "Ferox Hosting"],
        ["rendering tools", "Javascript", "Filing Findings"],
        ["React webapps", "Javascript", "the world"],
        ["Minecraft plugins", "Java", "lhridder"],
        ["DDOS protection", "C and eBPF", "Ferox Hosting"],
        ["Console UIs", "C using Ncurses", "Ferox Hosting"],
        ["Discord bots", "Javascript using Discord.js", "Ferox Hosting"],
        ["Discord bots", "Python using Discord.py", "Ferox Hosting"],
        ["automation scripts", "Bash", "Ferox Hosting"],
        ["Prometheus insights", "various languages", "Ferox Hosting"],
        ["server status insight", "Java", "Ferox Hosting"],
        ["inspiring websites", "PHP, HTML, and CSS", "Ferox Hosting"],
        ["chat applications", "your terminal using Python", "the world"],
        ["image tagging", "Python", "the world"],
        ["character recognition", "Python using Tensorflow", "the world"],
        //["the future of computing", "Brainfuck", "Lucas <3"],
        ["great applications", "many languages", "my clients"]
    ];
    const [experienceId, setExperienceId] = useState(Math.floor(Math.random() * experience.length));
    useEffect(() => {
        const intr = setInterval(() => {
            setExperienceId((old) => {
                let opt = Math.floor(Math.random() * experience.length);
                while(opt === old) opt = Math.floor(Math.random() * experience.length);
                return opt;
            });
        }, 5000);

        return () => {
            clearInterval(intr);
        }
    }, []);

    return (
        <div style={{
            backgroundColor: "black"
        }}>
            <Parallax
                strength={400}
            >
                <Background>
                    <div style={{
                        transform: "translateX(calc(50% - 50vw))",
                        height: "100vh"
                    }}>
                        <img src={process.env.PUBLIC_URL + "/banner.png"} style={{
                            height: "100%"
                        }} alt="Background" />
                    </div>
                </Background>
                <div style={{
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    flexWrap: "nowrap",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "stretch",
                    color: "white"
                }}>
                    <div style={{
                        flexGrow: 1,
                        flexBasis: 0,
                        display: "flex",
                        flexWrap: "nowrap",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "stretch",
                        marginTop: "7vh"
                    }}>
                        <div style={{
                            width: (document.documentElement.clientWidth > 512 ? "50vw" : "100vw"),
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}>
                            <img src={process.env.PUBLIC_URL + "/snowp.png"} alt="Snowp Logo" style={{
                                marginLeft: (document.documentElement.clientWidth > 512 ? "0" : "5vw"),
                                marginRight: "5vw",
                                maxWidth: (document.documentElement.clientWidth > 512 ? "max(30vw, 400px)" : "90vw"),
                                height: "auto",
                            }} data-sal="slide-up" data-sal-delay="200" data-sal-duration="800"/>
                            {/*
                            <h2 style={{
                                marginLeft: 0,
                                marginTop: "60px"
                            }}></h2>
                            */}
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        userSelect: "none",
                        marginBottom: "7vh"
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                             className="bounce" viewBox="0 0 16 16" onClick={scrollToContent} style={{
                                 cursor: "pointer"
                        }}>
                            <path fillRule="evenodd"
                                  d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                        </svg>
                    </div>
                </div>
            </Parallax>
            <div style={{
                height: "100vh",
                boxSizing: "border-box",
                backgroundColor: "white",
                padding: "10vh 5vw",
                textAlign: "center"
            }} className="mobile-columns" ref={content}>
                {/*
                <h1 data-sal="slide-up" data-sal-delay="200" data-sal-duration="500">Developing advanced systems and experiences</h1>
                <br/><br/>
                <div className="infoBoxes">
                    <div data-sal="fade" data-sal-delay="400" data-sal-duration="500">
                        Ferox Hosting
                    </div>
                    <div data-sal="fade" data-sal-delay="400" data-sal-duration="500">
                        lhridder
                    </div>
                </div>
                <div className="infoBoxes">
                    <div data-sal="fade" data-sal-delay="500" data-sal-duration="500">
                        bababooey
                    </div>
                    <div data-sal="fade" data-sal-delay="500" data-sal-duration="500">
                        Content2
                    </div>
                </div>
                <div className="infoBoxes">
                    <div data-sal="fade" data-sal-delay="600" data-sal-duration="500">
                        Content
                    </div>
                    <div data-sal="fade" data-sal-delay="600" data-sal-duration="500">
                        Content2
                    </div>
                </div>*/}
                <div style={{
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center"
                }}>
                    <div style={{
                        width: "100%",
                        textAlign: "left",
                        fontSize: "max(min(calc(100vw / 70), 25px), 12px)",
                        whiteSpace: "nowrap"
                    }}>
                        <h1 data-sal="fade" data-sal-delay="0" data-sal-duration="300">Creating <SwitchTransition>
                            <CSSTransition
                                key={experience[experienceId][0]}
                                timeout={500}
                                classNames="experience"
                            >
                                <span className={"experience"}>{experience[experienceId][0]}</span>
                            </CSSTransition>
                        </SwitchTransition></h1>
                        <h1 data-sal="fade" data-sal-delay="200" data-sal-duration="300">in <SwitchTransition>
                            <CSSTransition
                                key={experience[experienceId][1]}
                                timeout={500}
                                classNames="experience"
                            >
                                <span className={"experience"}>{experience[experienceId][1]}</span>
                            </CSSTransition>
                        </SwitchTransition></h1>
                        <h1 data-sal="fade" data-sal-delay="400" data-sal-duration="300">for <SwitchTransition>
                            <CSSTransition
                                key={experience[experienceId][2]}
                                timeout={500}
                                classNames="experience"
                            >
                                <span className={"experience"}>{experience[experienceId][2]}</span>
                            </CSSTransition>
                        </SwitchTransition></h1>
                    </div>
                </div>
                <div style={{
                    textAlign: "left",
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center",
                    alignItems: "flex-start"
                }}>
                    <div style={{
                        marginTop: "40px",
                        marginBottom: "40px"
                    }}>
                        <h1>I am Snowp.</h1>
                        <i>an independent developer working on just about anything</i>
                        <br/>
                        <p>With experience on a wide range of subjects, stretching from DDoS protection to developing user dashboards or collaborating on open-source code, I am able to deliver the best experience for you and your clients.<br/><br/>Thanks to my versatile knowledge of software and its workings, I am able to quickly understand what a project needs, and what the best way to contribute would be. I am able to write reliable software from scratch or join in on ongoing projects, both for individuals with an idea or companies needing support on their latest project. I am streamlined, focussed, but also up for a nice conversation at the right time. I love computers and everything surrounding them, so almost anything will grab my attention.<br/><br/>Do you have a revolutionary idea, or want to change the world? Let's do it together.</p>
                        <br/>
                        <button className="btn" onClick={(e) => history.push("/about")}>Get in touch</button>
                    </div>
                </div>
                <br/>
            </div>
        </div>
    )
}

export default PageHome;
