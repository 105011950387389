import React from "react";
import {Link} from "react-router-dom";

function PageNotFound()
{
    return (
        <div style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "white",
            boxSizing: "border-box",
            paddingTop: "8vh",
            textAlign: "center"
        }}>
            <h1>It seems like you're lost</h1>

            <div style={{
                marginTop: "30vh"
            }}>
                <p>I know you like it here, but sadly, there isn't much to do..</p>
                <p>Maybe you would like to return to the <Link to="/">homepage</Link>?</p>
            </div>

            <p style={{
                position: "absolute",
                color: "#666",
                bottom: "20px",
                width: "100vw",
                fontSize: "12px"
            }}>
                404 Page Not Found
            </p>
        </div>
    )
}

export default PageNotFound;
