import React from "react";
import {Link} from "react-router-dom";

function PagePortfolioChatsock()
{   
    return (
        <div style={{
            height: "100vh",
            backgroundColor: "white",
            boxSizing: "border-box",
            paddingRight: "5vw",
            paddingLeft: "5vw",
            paddingTop: "8vh",
            textAlign: "center"
        }}>
            <h1>chatsock</h1>
            <p><a href="https://github.com/SnowpMakes/chatsock"><i>https://github.com/SnowpMakes/chatsock</i></a></p>
            <p>chatsock is an online chat application within your terminal. It does not require you to download a client as the entire interface is pushed through the connection.</p>
            <p>I was inspired to make this by various other fun things you could do in your terminal, like <code>cowsay</code> and <code>telnet towel.blinkenlights.nl</code>.</p>
            <p>Especially the former motivated me to try to make a chatting application within a terminal that works without an application-specific client.</p>
            <br/>
            <i>Technicalities:</i>
            <p>chatsock uses a socket connection, usually through telnet, to communicate with a client terminal. It's a raw TCP connection without any client application.<br/>All user state, like registered username and connected rooms, is handled server side.</p>
            <br/><br/>
            <div style={{
                display: "flex",
                width: "100%",
                boxSizing: "border-box",
            }} className={"mobile-columns"}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 10px"
                }}>
                    <img src="/static/portfolio-images/chatsock-menu.png" style={{
                        width: "100%",
                        height: "auto"
                    }}/>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 10px"
                }}>
                    <img src="/static/portfolio-images/chatsock-chat.png" style={{
                        width: "100%",
                        height: "auto"
                    }}/>
                </div>
            </div>
        </div>
    )
}

export default PagePortfolioChatsock;
