import React from "react";
import { Link } from "react-router-dom";

function PagePortfolio()
{
    return (
        <div style={{
            height: "100vh",
            backgroundColor: "white",
            boxSizing: "border-box",
            paddingTop: "8vh",
            paddingLeft: "5vw",
            paddingRight: "5vw",
            textAlign: "center"
        }}>
            <h1>Portfolio</h1>
            <div style={{
                display: "flex",
                width: "100%",
                marginTop: "30px",
                boxSizing: "border-box",
                minHeight: "400px",
            }} className={"mobile-columns"}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 0"
                }}>
                    <Link to="/portfolio/lredis">
                        <div className={"gallery-image"}>
                            <img src="/static/portfolio-images/lredis.png" alt="LRedis"/>
                            <div>LRedis</div>
                        </div>
                    </Link>
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 0"
                }}>
                    <Link to="/portfolio/nbashes">
                        <div className={"gallery-image"}>
                            <img src="/static/portfolio-images/nbashes.png" alt="nbashes"/>
                            <div>nbashes</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div style={{
                display: "flex",
                width: "100%",
                boxSizing: "border-box",
                minHeight: "400px",
            }} className={"mobile-columns"}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 0"
                }}>
                    <Link to="/portfolio/snowpio">
                        <div className={"gallery-image"}>
                            <img src="/static/portfolio-images/snowp.png" alt="snowp.io"/>
                            <div>snowp.io</div>
                        </div>
                    </Link>
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 0"
                }}>
                    <Link to="/portfolio/chatsock">
                        <div className={"gallery-image"}>
                            <img src="/static/portfolio-images/chatsock.png" alt="chatsock"/>
                            <div>chatsock</div>
                        </div>
                    </Link>
                </div>
            </div>
            <br/>
            <i>More to be added and more to be created in the near future.<br/><br/>Stay tuned...</i>
        </div>
    )
}

export default PagePortfolio;
