import React from "react";
import PageError from "./pages/Error";

interface ErrorProps
{

}

interface ErrorState
{
    hasError: boolean;
}

export default class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
    constructor(props: ErrorProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <div><PageError/></div>;
        }

        return this.props.children;
    }
}
