import React from "react";
import {Link} from "react-router-dom";

function PageError()
{
    return (
        <div style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "white",
            boxSizing: "border-box",
            paddingTop: "8vh",
            textAlign: "center"
        }}>
            <h1>Something went wrong</h1>

            <div style={{
                marginTop: "30vh"
            }}>
                <p>If you feel like reporting what happened <a href="/contact">contact me</a>,</p>
                <p>but otherwise you should probably return to the <a href="/">homepage</a>..</p>
            </div>

            <p style={{
                position: "absolute",
                color: "#666",
                bottom: "20px",
                width: "100vw",
                fontSize: "12px"
            }}>
                500 Internal Server Error
            </p>
        </div>
    )
}

export default PageError;
