import React from "react";
import {Link} from "react-router-dom";

function PagePortfolioSnowpio()
{   
    return (
        <div style={{
            height: "100vh",
            backgroundColor: "white",
            boxSizing: "border-box",
            paddingRight: "5vw",
            paddingLeft: "5vw",
            paddingTop: "8vh",
            textAlign: "center"
        }}>
            <h1>snowp.io</h1>
            <p><a href="https://snowp.io"><i>https://snowp.io</i></a></p>
            <p>snowp.io is my little corner of the internet. Chances are, you might already be quite familiar with it if you're reading this ;)</p>
            <p>Not only is it where I present my portfolio to the world, but it also hosts several in-progress projects of mine.</p>
            <p>It has been created as to demonstrate my skills and show the effort I put into my applications for the best possible user experience.</p>
            <br/>
            <i>Technicalities:</i>
            <p>snowp.io is a SPA webapp built with react and react-router, powered by an nginx backend serving the files.<br/>All styling and layouts are fully custom made.</p>
            <br/><br/>
            <div style={{
                display: "flex",
                width: "100%",
                boxSizing: "border-box",
            }} className={"mobile-columns"}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 10px"
                }}>
                    <img src="/static/portfolio-images/snowp-home.png" style={{
                        width: "100%",
                        height: "auto"
                    }}/>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px 10px"
                }}>
                    <img src="/static/portfolio-images/snowp-code.png" style={{
                        width: "100%",
                        height: "auto"
                    }}/>
                </div>
            </div>
        </div>
    )
}

export default PagePortfolioSnowpio;
